import React, { useState, useEffect, useRef, useContext } from "react";
import { navigate } from 'gatsby'
import '../../assets/css/category.scss'

import { Text, LanguageCtx } from '../../context/LanguageCtx';
import { PerformancesCtx } from "../../context/PerformancesCtx";
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import Image from "../../components/Image"

import { Box, Button, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

// Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Animation on scroll
import AOS from "aos";
import "aos/dist/aos.css";

import CategoryVideoBtnRow from "../../components/CategoryVideoBtnRow";
import CategoryGallery from "../../components/CategoryGallery";

const getImages = (performanceUnique, images) => {
  let imagesList = []
  for (var i = 0; i < images.length; i++) {
    if(images[i]) {
      imagesList.push(<Image key={i} className="slider-image fullSize" alt={`${performanceUnique}-${i}`} filename={`${performanceUnique}-${i}.jpg`}/>)
    }
  }
  return imagesList
}

function SliderImages({ performanceUnique, images, setChangedImage }) {
  const settings = {
    className: 'slider-images',
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    swipe: false,
    speed: 500,
    autoplaySpeed: 5500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    beforeChange: () => {
      setChangedImage(true)
    }
  };

  return (
    <Slider {...settings}>
      {getImages(performanceUnique, images)}
    </Slider>
  )
};

function SliderReviews({ performanceUnique, reviewsCount, changedImage, setChangedImage, changedPerformance, setChangedPerformance }) {
  const { getText } = useContext(LanguageCtx);
  const slickerRef = useRef(null);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    swipeToSlide: false,
    swipe: false,
    focusOnSelect: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    ref: slickerRef
  };

  useEffect(() => {
    if(changedImage || changedPerformance) {
      slickerRef.current.slickNext();
    }
    setChangedPerformance(false)
    setChangedImage(false)
  }, [changedImage, setChangedImage, changedPerformance, setChangedPerformance])

  const getQuotes = () => {
    let quotesList = []
    for (var i = 1; i <= reviewsCount; i++) {
      quotesList.push(
        <Box key={i} className="slider-review t-center middle">
          <Box className="slider-review-info t-bold">
            <q>{getText(`${performanceUnique}.review-${i}`)}</q>
          </Box>
          <Box className="slider-review-author push-top">{getText(`${performanceUnique}.review-${i}.author`)}</Box>
        </Box>
      )
    }
    return quotesList
  }

  return (
    <Slider {...settings}>
      {getQuotes()}
    </Slider>
  )
};

function SliderMenu({ performances, startedMenuPosition, handleChangeSelectedPerformance }) {
  const settings = {
    className: 'category-menu',
    arrows: false,
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 1000,
    slidesToShow: performances.length > 4 ? 5 : 3,
    focusOnSelect: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: startedMenuPosition,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: performances.length > 4 ? 3 : performances.length,
        }
      },
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1,
          arrows: true,
          centerMode: false,
          nextArrow: <NextArrow/>,
          prevArrow: <PrevArrow/>,
        }
      }
    ],
    beforeChange: (current, next) => {
      handleChangeSelectedPerformance(performances[next])
    }
  };
  
  function PrevArrow({ onClick }) {
      return (
        <IconButton className="btn btn-3 no-padd slick-arrow slick-prev-arrow" onClick={() => onClick()}>
          <KeyboardArrowLeftIcon/>
        </IconButton>
      );
  }

  function NextArrow({ onClick }) {
      return (
        <IconButton className="btn btn-3 no-padd slick-arrow slick-next-arrow" onClick={() => onClick()}>
          <KeyboardArrowRightIcon/>
        </IconButton>
      );
  }

  return (
    <>
     {performances.length > 0 &&
      <Slider {...settings}>
        {performances.map((performance) => {
          return (
            <Box key={performance.unique} className="category-menu-item t-center hand">
              <Text tid={`${performance.unique}.prefix`}/> <Text tid={`${performance.unique}.name`}/>
            </Box>
          )
        })}
      </Slider>
    }
    </>
  )
};

// Some performances does not have poster, if this situaction occurs it needs to delete poster element  
const togglePosterInfo = (show) => {
  let posterInfo = document.getElementById("poster-info");
  if(posterInfo) {
    if(show)
      posterInfo.style.display = "block";
    else
      posterInfo.style.display = "none";
  }
}

const getTheaterInfo = (theatre) => {
  return (
    <div className="t-center theatre-info padd-btm padd-top">
      {theatre.status === 0 ?
        <>
          {theatre.name != null && 
            <div className="t-red t-bold">
              {theatre.name && <><Text tid={`${theatre.name}`}/> | </>}<Text tid={`${theatre.location}`}/>
            </div>
          }
          <div className="t-bold">
            {theatre.premiere[0]} - {theatre.derniere}
          </div>
          <div className="t-red">
            <FiberManualRecordIcon />
          </div>
        </>
      : theatre.status === 1 ?
        <>
          <div className="t-green t-bold">
            {theatre.name && <><Text tid={`${theatre.name}`}/> | </>}<Text tid={`${theatre.location}`}/>
          </div>
          <div className="t-bold">
            <Text tid="category.premiere"/> {theatre.premiere.join(', ')}
          </div>
          <div className="t-green">
            <FiberManualRecordIcon />
          </div>
          <Text tid="category.current"/>
        </>
      : theatre.status === 2 ?
        <>
          <div className="t-gray t-bold">
            {theatre.name && <><Text tid={`${theatre.name}`}/> | </>}<Text tid={`${theatre.location}`}/>
          </div>
          {theatre.premiere.length > 0 &&
            <div className="t-bold">
              <Text tid="category.premiere"/> {theatre.premiere[0]}
            </div>
          }
          <div className="t-gray">
            <FiberManualRecordIcon />
          </div>
          <Text tid="category.expected"/>
        </>
      :
        <>
          <div className="t-bold">
            {theatre.name && <><Text tid={`${theatre.name}`}/> | </>}<Text tid={`${theatre.location}`}/>
          </div>
          <div className="t-green">
            <FiberManualRecordIcon />
          </div>
          <Text tid="category.current"/>
        </>
      }
    </div>
  )
}

function CategoryPage() {
  const { getPerformances } = useContext(PerformancesCtx);
  const { getText } = useContext(LanguageCtx);
  const [performances, setPerformances] = useState([])
  const [selectedPerformance, setSelectedPerformance] = useState(null)
  const [showVideo, setShowVideo] = useState(null)
  const [showVideoMini, setShowVideoMini] = useState(false)
  const [showVideoOptions, setShowVideoOptions] = useState(false)
  const [changedImage, setChangedImage] = useState(false)
  const [changedPerformance, setChangedPerformance] = useState(false)
  const [startedMenuPosition, setStartedMenuPosition] = useState(0)
  const performanceInfoRef = useRef(null)
  const [isMouseMoving, setMouseMoving] = useState(true)

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
    
    const unique = new URLSearchParams(window.location.search).get("id")
    if(!unique) {
      showProductionPage()
      return
    }
    const tmpPerformance = getPerformances().find((performance) => {
      if(performance.unique === unique) {
        return performance 
      }
    })

    setSelectedPerformance(tmpPerformance)
    setPerformances(getPerformances(tmpPerformance.category, true))
    setStartedMenuPosition(tmpPerformance.id - 1)
    
    window.addEventListener('scroll', handleshowVideoMini)
    window.addEventListener("mousemove", handleMouseMove);
    return function cleanupListener() {
      window.removeEventListener('scroll', handleshowVideoMini) // remove listener when leaving from the page
      window.removeEventListener("mousemove", handleMouseMove); // remove listener when leaving from the page
      if (timer) clearTimeout(timer);
    }
  }, [])
  
  let timer = null;
  const handleMouseMove = () => {
    setMouseMoving(true)
    
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setMouseMoving(false)
    }, 1000);
  }
  
  const handleshowVideoMini = () => {
    if (window.scrollY >= 1) {
      setShowVideoMini(true)
    } else {
      setShowVideoMini(false)
    }
  }

  const showProductionPage = () => {
    navigate('/production')
  }

  const scrollToPerformanceInfo = () => {
    window.scrollTo({
        top: performanceInfoRef.current.offsetTop - 147,
        left: 0,
        behavior: 'smooth'
    });
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const toggleShowVideo = (playTrailer) => {
    if(playTrailer == null) {
      setShowVideo(null)
      setShowVideoOptions(false)
    } else {
      if(!showVideo) {
        scrollToTop()
      }
      if(playTrailer) {
        setShowVideo(selectedPerformance.trailer)
      } else {
        setShowVideo(selectedPerformance.spot)
      }
    }
  }

  const handleChangeSelectedPerformance = (newSelectedPerformance) => {
    togglePosterInfo(true)
    setShowVideo(null) // hide video
    setShowVideoOptions(false)
    setSelectedPerformance(newSelectedPerformance)
    
    // change id of new selected performance in URL
    window.history.pushState({}, null, `/production/category/?id=${encodeURIComponent(newSelectedPerformance.unique)}`);

    setChangedImage(false)
    setChangedPerformance(true)
  }

  const handleVideoBtnClick = () => {
    if(showVideo != null || (selectedPerformance.spot == null && selectedPerformance.trailer == null)) {
      setShowVideo(null)
      setShowVideoOptions(false)
    } else {
      if(selectedPerformance.spot != null && selectedPerformance.trailer != null) {
        setShowVideoOptions(true)
      } else {
        toggleShowVideo(selectedPerformance.trailer != null)
      }
    }
  }

  const getQuotes = () => {
    let quotesList = []
    for (var i = 1; i <= selectedPerformance.reviews_count; i++) {
      quotesList.push(
        <p key={i} className="quote-block t-center push-btm">
            <q>{getText(`${selectedPerformance.unique}.review-${i}`)}</q>
            <span className="t-bold push-top-hlf blc">{getText(`${selectedPerformance.unique}.review-${i}.author`)}</span>
        </p>
      )
    }
    return quotesList
  }

  return (
    <>
      {selectedPerformance != null &&
        <Layout headerType={0}>
          <SEO title={getText("category")} />
          <Box id="category-page" className="page">
            <div className="fullScreen">
              <div className="slider-wrapper pos-rel">
                {selectedPerformance.images.length > 0 &&
                  <SliderImages performanceUnique={selectedPerformance.unique} images={selectedPerformance.images} setChangedImage={setChangedImage}/>
                }
                <div className="category-menu-wrapper t-white fullWidth">
                  <SliderMenu performances={performances} startedMenuPosition={startedMenuPosition} handleChangeSelectedPerformance={handleChangeSelectedPerformance} />
                  <CategoryVideoBtnRow 
                    showVideo={showVideo}
                    showVideoMini={showVideoMini}
                    isMouseMoving={isMouseMoving}
                    showVideoOptions={showVideoOptions}
                    toggleShowVideo={toggleShowVideo}
                    showProductionPage={showProductionPage}
                    scrollToPerformanceInfo={scrollToPerformanceInfo}
                    handleVideoBtnClick={handleVideoBtnClick}
                  />
                </div>
                <div className="category-reviews-wrapper fullSize t-white">
                  <SliderReviews performanceUnique={selectedPerformance.unique} reviewsCount={selectedPerformance.reviews_count} changedImage={changedImage} setChangedImage={setChangedImage} changedPerformance={changedPerformance} setChangedPerformance={setChangedPerformance}/>
                </div>
                {showVideo !=null && 
                  <>
                    {!showVideoMini &&
                      <Box className="overlay pos-abs"/>
                    }
                    <Box className={`trailer-video-wrapper ${showVideoMini ? "mini flex-column" : isMouseMoving ? "with-toolbar" : ""}`}>
                      {showVideoMini &&
                        <IconButton className="t-red close-btn" onClick={() => toggleShowVideo(null)}>
                          <CloseIcon />
                        </IconButton>
                      }
                      <iframe id="performance-trailer" className={`${showVideoMini ? "flex-grow fullWidth" : "fullSize"}`} 
                        src={`${showVideo}?autoplay=1`}
                        frameBorder='0'
                        allow='autoplay; encrypted-media'
                        title={`${getText(selectedPerformance.unique)} - video`}
                      />
                    </Box>
                  </>
                }
              </div>
              <Box className="more-info-title t-center padd-hlf hand" onClick={() => scrollToPerformanceInfo()}>
                <Text tid="more.info"/>
              </Box>
            </div>
            <Box ref={performanceInfoRef} className="intro-text padd-top t-bold t-center">
              <Box className="performance-name push-top">
                <Box>
                  <Text tid={`${selectedPerformance.unique}.prefix`}/> 
                </Box>
                <Box>
                  <Text tid={`${selectedPerformance.unique}.name`}/>
                </Box>
              </Box>
              {selectedPerformance.is_inspired &&
                <>
                  <Box className="inspired-by-text">
                    <Text tid="home.inspired-by"/>
                  </Box>
                  <Box className="performance-name o-mid">
                    <Text tid={`${selectedPerformance.unique}.inspired-by`}/>
                  </Box>
                </>
              }
              <Box className="category-text t-normal push-btm-dbl">
                <Text tid={selectedPerformance.category}/>
              </Box>
              {selectedPerformance.theaters.map((theatre, index) => {
                return (
                  <div key={index}>
                    {getTheaterInfo(theatre)}
                  </div>
                )
              })}
            </Box>
            <Box className="page-content content-light padd-btm t-center">
              <Box id="poster-info" className="padd-btm">
                <Image className="push-btm-hlf push-top" alt={`${selectedPerformance.unique} - poster`} filename={`${selectedPerformance.unique}-poster.jpg`} onError={() => togglePosterInfo(false)}/>
                <span>(<Text tid="category.poster"/>)</span>
              </Box>
              {selectedPerformance.reviews_count !== 0 &&
                <>
                  <h3 className='page-title padd-top'><Text tid="category.review"/></h3>
                  {getQuotes()}
                </>
              }
            </Box>
            <CategoryGallery performanceUnique={selectedPerformance.unique} imagesCount={selectedPerformance.images.length}/>
            {selectedPerformance.unique === 'chorea' &&
              <Box className="add-info t-bold t-center">
                <Text tid="chorea.add-info"/>
              </Box>
            }
            <Box className='t-center padd-btm-dbl push-btm push-top-dbl'>
              <Button onClick={() => showProductionPage()} className='btn btn-1'><Text tid="category.back"/></Button>
            </Box>
          </Box>
        </Layout>
      }
    </>
  );
}
export default CategoryPage;