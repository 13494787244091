import React, { useState, useEffect, useRef, memo } from 'react'

import { ClickAwayListener, Box, IconButton, SvgIcon, Grid } from "@material-ui/core";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CloseIcon from '@material-ui/icons/Close';

import Image from './Image';

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { toggleFullScreen } from '../assets/data'

function SliderGallery({ startedPresentationIndex, performanceUnique, imagesCount, closePresentation }) {
    const [nav, setNav] = useState(null);
    const [navThumbnail, setNavThumbnail] = useState(null);
    const slickerRef = useRef(null);
    const slickerRefThumbnail = useRef(null);

    useEffect(() => {
        setNav(slickerRef.current);
        setNavThumbnail(slickerRefThumbnail.current);
    }, []);

    const settings = {
        className: "preview",
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        swipeToSlide: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: navThumbnail,
        ref: slickerRef,
        initialSlide: startedPresentationIndex,
    };

    const settingsThumbnail = {
        className: "thumbnail",
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToScroll: 1,
        asNavFor: nav,
        ref: slickerRefThumbnail,
        slidesToShow: 7,
        swipeToSlide: true,
        focusOnSelect: true,
        initialSlide: startedPresentationIndex,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            }
        ],
    };

    const getImagesPreview = () => {
        let imagesList = []
        for (var i = 0; i < imagesCount; i++) {
            imagesList.push(
                <Box key={i} className="preview-image-wrapper padd">
                    <ClickAwayListener onClickAway={(event) => closePresentation(event)}>
                        <span>
                            <Image className="fullSize" alt={`${performanceUnique}-${i}`} filename={`${performanceUnique}-${i}.jpg`} />
                        </span>
                    </ClickAwayListener>
                </Box>
            )
        }
        return imagesList
    }

    const getImagesThumbnail = () => {
        let imagesList = []
        for (var i = 0; i < imagesCount; i++) {
            imagesList.push(
                <Box key={i} className="thumbnail-image-wrapper">
                    <ClickAwayListener onClickAway={(event) => closePresentation(event)}>
                        <span>
                            <Image className="fullSize" alt={`${performanceUnique}-${i}`} filename={`${performanceUnique}-${i}.jpg`} />
                        </span>
                    </ClickAwayListener>
                </Box>
            )
        }
        return imagesList
    }
  
    return (
        <Box className="presentation fullWidth">
            <IconButton className="t-white close-btn" onClick={closePresentation}>
                <CloseIcon />
            </IconButton>
            <Grid container justify="center" className="preview-wrapper">
                <Grid item lg={9} xs={11} className="fullHeight">
                    <Slider {...settings}>
                        {getImagesPreview()}
                    </Slider>
                </Grid>
            </Grid>
            <Box className="thumbnail-wrapper">
                <Slider {...settingsThumbnail}>
                    {getImagesThumbnail()}
                </Slider>
            </Box>
        </Box>
    )
};

function CategoryGallery({ performanceUnique, imagesCount }) {
    const [startedPresentationIndex, setStartedPresentationIndex] = useState(-1)
    const [showPresentation, setShowPresentation] = useState(false)

    useEffect(() => {
        document.addEventListener("fullscreenchange", escFunction)
        return function cleanupListener() {
            document.removeEventListener("fullscreenchange", escFunction);
        }
    }, [])

    const escFunction = () => {
        if(!document.fullscreen) {
            handleClosePresentation()
        }
    }

    const handleShowPresentation = (index) => {
        toggleFullScreen()
        setStartedPresentationIndex(index)
        setShowPresentation(true)
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden'
    }

    const handleClosePresentation = () => {
        setStartedPresentationIndex(-1)
        setShowPresentation(false)
        document.getElementsByTagName('body')[0].style.overflowY = 'auto'
    }

    const closePresentation = (event) => {
        if(event !== null) {
            let target = event.target
            if(target.classList.contains('thumbnail-image-wrapper') || 
                target.closest(".thumbnail-image-wrapper") !== null || 
                target.classList.contains('preview-image-wrapper') || 
                target.closest(".preview-image-wrapper") !== null) {
                // if clicked on thumbnail or preview image do not do nothing
                return
            }
        }
        toggleFullScreen()
        handleClosePresentation()
    }

    const getImages = () => {
        let imagesList = []
        for (let i = 0; i < imagesCount; i++) {
            imagesList.push(
                <Box key={i} className={`gallery-item-wrapper hand image-${i}`} onClick={() => handleShowPresentation(i)} data-aos="fade-up">
                    <Box className="gallery-item">
                        <Image className="fullSize" alt={`${performanceUnique}-${i}`} filename={`${performanceUnique}-${i}.jpg`} />
                        <Box className='overlay pos-abs'/>
                        <Box className="middle">
                            <IconButton className="t-white">
                                <SvgIcon style={{ fontSize: 50 }}>
                                    <FullscreenIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            )
        }
        return imagesList
    }

    return (
        <Grid container justify="center" className="gallery-wrapper pos-rel push-btm-dbl padd-btm-dbl">
            <Grid item lg={9} xs={11}>
                <Box className="gallery">
                    {getImages()}
                </Box>
            </Grid>
            {showPresentation &&
                <SliderGallery startedPresentationIndex={startedPresentationIndex} performanceUnique={performanceUnique} imagesCount={imagesCount} closePresentation={closePresentation}/>
            }
        </Grid>
    )
}

export default memo(CategoryGallery)