import React, { useContext } from 'react'
import { Text, LanguageCtx } from '../context/LanguageCtx';

import { Box } from "@material-ui/core";

import BackArrow from '../assets/icons/back_arrow.svg';

function CategoryVideoBtnRow({ showVideo, showVideoMini, isMouseMoving, showVideoOptions, toggleShowVideo, showProductionPage, handleVideoBtnClick, scrollToPerformanceInfo }) {
    const { getText } = useContext(LanguageCtx);

    return (
        <Box className={`trailer-row-wrapper flex-row fullWidth pos-abs ${showVideo != null && !showVideoMini ? "" : "transparent" } ${!(showVideo != null && !isMouseMoving) || showVideoMini ? "active" : ""}`}>
            <Box className="padd-lft side-btn padd-rgt t-red hand t-bold" onClick={() => showProductionPage()}>
                <img className="back-arrow" alt="Back to category" src={BackArrow}/>
                <Text tid="category.back"/>
            </Box>
            <span className="flex-grow red-line"></span>
            {showVideoOptions && <span className="t-center trailer-play hand" onClick={() => toggleShowVideo(true)}>trailer</span>}
            <Box onClick={handleVideoBtnClick} className={`hand video-btn btn no-padd push-lft push-rgt btn-4 ${showVideo != null ? "stop" : ""}`} title={getText("category.watch")}>
                <span className="bar bar-1"></span>
                <span className="bar bar-2"></span>
                <span className="bar bar-3"></span>
            </Box>
            {showVideoOptions && <span className="t-center spot-play hand" onClick={() => toggleShowVideo(false)}>spot</span>}
            <span className="flex-grow red-line"></span>
            <Box className="padd-lft side-btn padd-rgt t-center t-red hand t-bold" onClick={() => scrollToPerformanceInfo()}>
                <Text tid="more.info"/>
            </Box>
        </Box>
    )
}

export default CategoryVideoBtnRow
